<template>
	<div class="info-operate">
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">变更信息
						<ul class="titleTabsBox">
							<li v-for="(item,index) in titleTabs1" :key="index" @click="titleTabsFn1(item,index)" :class="index == titleTabsIndex1 ? 'titleTabsActive' : ''">
								<b>{{item.name}}（{{item.value}}）</b>
							</li>
						</ul>
					</h4>
					<div v-show="titleTabsIndex1 == 0">
						<div class="table-box">
							<el-table border v-loading="loadingFlag11" :data="tableData11" v-el-table-infinite-scroll="getPageList11" infinite-scroll-disabled="scrollFlag11" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="changeTime" label="变更时间" width="100"></el-table-column>
								<el-table-column prop="contentBefore" label="变更前" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="contentAfter" label="变更后" :show-overflow-tooltip="true"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty11" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
					<div v-if="titleTabsIndex1 == 1">
						<div class="table-box">
							<el-table border v-loading="loadingFlag12" :data="tableData12" v-el-table-infinite-scroll="getPageList12" infinite-scroll-disabled="scrollFlag12" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="changeTime" label="变更时间" width="100"></el-table-column>
								<el-table-column prop="contentBefore" label="变更前" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="contentAfter" label="变更后" :show-overflow-tooltip="true"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty12" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
					<div v-if="titleTabsIndex1 == 2">
						<div class="table-box">
							<el-table border v-loading="loadingFlag13" :data="tableData13" v-el-table-infinite-scroll="getPageList13" infinite-scroll-disabled="scrollFlag13" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="changeTime" label="变更时间" width="100"></el-table-column>
								<el-table-column prop="contentBefore" label="变更前" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="contentAfter" label="变更后" :show-overflow-tooltip="true"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty13" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">异常信息
						<ul class="titleTabsBox">
							<li v-for="(item,index) in titleTabs2" :key="index" @click="titleTabsFn2(item,index)" :class="index == titleTabsIndex2 ? 'titleTabsActive' : ''">
								<b>{{item.name}}（{{item.value}}）</b>
							</li>
						</ul>
					</h4>
					<div v-show="titleTabsIndex2 == 0">
						<div class="table-box">
							<el-table border v-loading="loadingFlag21" :data="tableData21" v-el-table-infinite-scroll="getPageList21" infinite-scroll-disabled="scrollFlag21" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="putDate" label="异常时间" width="100"></el-table-column>
								<el-table-column prop="putDepartment" label="决定机关" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="putReason" label="异常原因" :show-overflow-tooltip="true"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty21" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
					<div v-if="titleTabsIndex2 == 1">
						<div class="table-box">
							<el-table border v-loading="loadingFlag22" :data="tableData22" v-el-table-infinite-scroll="getPageList22" infinite-scroll-disabled="scrollFlag22" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="webUrl" label="网址"></el-table-column>
								<el-table-column prop="isRecord" label="是否备案" width="100" :show-overflow-tooltip="true">
									<template slot-scope="scope">
										<span v-if="scope.row.isRecord == '0'">未备案</span>
										<span v-if="scope.row.isRecord == '1'">已备案</span>
									</template>
								</el-table-column>
								<el-table-column prop="isOverseas" label="是否在境外" width="100" :show-overflow-tooltip="true">
									<template slot-scope="scope">
										<span v-if="scope.row.isOverseas == '0'">境内</span>
										<span v-if="scope.row.isOverseas == '1'">境外</span>
										<span v-if="scope.row.isOverseas == '2'">未知</span>
									</template>
								</el-table-column>
								<el-table-column prop="isFanwen" label="运营状态" width="100" :show-overflow-tooltip="true">
									<template slot-scope="scope">
										<span v-if="scope.row.isFanwen == '1'">在运营</span>
										<span v-if="scope.row.isFanwen == '2'">否</span>
										<span v-if="scope.row.isFanwen == '3'">未知</span>
									</template>
								</el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty22" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">经营状况
						<ul class="titleTabsBox">
							<li v-for="(item,index) in titleTabs3" :key="index" @click="titleTabsFn3(item,index)" :class="index == titleTabsIndex3 ? 'titleTabsActive' : ''">
								<b>{{item.name}}（{{item.value}}）</b>
							</li>
						</ul>
					</h4>
					<div v-show="titleTabsIndex3 == 0">
						<div class="table-box">
							<el-table border v-loading="loadingFlag31" :data="tableData31" v-el-table-infinite-scroll="getPageList31" infinite-scroll-disabled="scrollFlag31" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="companyName" label="分公司名称"></el-table-column>
								<el-table-column prop="estiblishTime" label="注册时间" width="100"></el-table-column>
								<el-table-column prop="regCapital" label="注册资本" width="200"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty31" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
					<div v-if="titleTabsIndex3 == 1">
						<div class="table-box">
							<el-table border v-loading="loadingFlag32" :data="tableData32" v-el-table-infinite-scroll="getPageList32" infinite-scroll-disabled="scrollFlag32" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="companyName" label="被投资公司名称"></el-table-column>
								<el-table-column prop="decimalAmount" label="投资金额(万元)" width="200"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty32" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
					<div v-if="titleTabsIndex3 == 2">
						<div class="table-box">
							<el-table border v-loading="loadingFlag33" :data="tableData33" v-el-table-infinite-scroll="getPageList33" infinite-scroll-disabled="scrollFlag33" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="publishTime" label="发布时间"></el-table-column>
								<el-table-column prop="jobName" label="招聘职位"></el-table-column>
								<el-table-column prop="jobSalary" label="薪资"></el-table-column>
								<el-table-column prop="jobExperience" label="工作经验"></el-table-column>
								<el-table-column prop="recruitNumber" label="招聘人数"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty33" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">质押信息
						<ul class="titleTabsBox">
							<li v-for="(item,index) in titleTabs4" :key="index" @click="titleTabsFn4(item,index)" :class="index == titleTabsIndex4 ? 'titleTabsActive' : ''">
								<b>{{item.name}}（{{item.value}}）</b>
							</li>
						</ul>
					</h4>
					<div v-show="titleTabsIndex4 == 0">
						<div class="table-box">
							<el-table border v-loading="loadingFlag41" :data="tableData41" v-el-table-infinite-scroll="getPageList41" infinite-scroll-disabled="scrollFlag41" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="regDate" label="登记时间"></el-table-column>
								<el-table-column prop="pledgor" label="出质人"></el-table-column>
								<el-table-column prop="pledgee" label="质权人"></el-table-column>
								<el-table-column prop="equityAmount" label="出质股权数"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty41" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
					<div v-if="titleTabsIndex4 == 1">
						<div class="table-box">
							<el-table border v-loading="loadingFlag42" :data="tableData42" v-el-table-infinite-scroll="getPageList42" infinite-scroll-disabled="scrollFlag42" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="regDate" label="登记时间"></el-table-column>
								<el-table-column prop="regNum" label="登记编号"></el-table-column>
								<el-table-column prop="mtType" label="被担保债权类型"></el-table-column>
								<el-table-column prop="mtAmount" label="被担保债权数额"></el-table-column>
								<el-table-column prop="regDepartment" label="登记机关"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty42" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import elTableInfiniteScroll from 'el-table-infinite-scroll';
export default {
	name: 'info-operate',
	directives: {
		'el-table-infinite-scroll': elTableInfiniteScroll
	},
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			titleTabsIndex1: 0,
			titleTabs1: [
				{name: "股东变更",value: 0},
				{name: "法人变更",value: 0},
				{name: "高管变更",value: 0}
			],
			titleTabsIndex2: 0,
			titleTabs2: [
				{name: "经营异常",value: 0},
				{name: "网站异常",value: 0},
			],
			titleTabsIndex3: 0,
			titleTabs3: [
				{name: "分公司",value: 0},
				{name: "投资公司",value: 0},
				{name: "招聘信息",value: 0}
			],
			titleTabsIndex4: 0,
			titleTabs4: [
				{name: "股权出质",value: 0},
				{name: "动产抵押",value: 0},
			],
			loadingFlag11: false,
			dataTableEmpty11: false,
			scrollFlag11: true,
			tableData11: [], //列表  数组
			currentPage11: 1, //当前页
			pageSize11: 20, //每页  条数
			loadingFlag12: false,
			dataTableEmpty12: false,
			scrollFlag12: true,
			tableData12: [], //列表  数组
			currentPage12: 1, //当前页
			pageSize12: 20, //每页  条数
			loadingFlag13: false,
			dataTableEmpty13: false,
			scrollFlag13: true,
			tableData13: [], //列表  数组
			currentPage13: 1, //当前页
			pageSize13: 20, //每页  条数
			loadingFlag21: false,
			dataTableEmpty21: false,
			scrollFlag21: true,
			tableData21: [], //列表  数组
			currentPage21: 1, //当前页
			pageSize21: 20, //每页  条数
			loadingFlag22: false,
			dataTableEmpty22: false,
			scrollFlag22: true,
			tableData22: [], //列表  数组
			currentPage22: 1, //当前页
			pageSize22: 20, //每页  条数
			loadingFlag31: false,
			dataTableEmpty31: false,
			scrollFlag31: true,
			tableData31: [], //列表  数组
			currentPage31: 1, //当前页
			pageSize31: 20, //每页  条数
			loadingFlag32: false,
			dataTableEmpty32: false,
			scrollFlag32: true,
			tableData32: [], //列表  数组
			currentPage32: 1, //当前页
			pageSize32: 20, //每页  条数
			loadingFlag33: false,
			dataTableEmpty33: false,
			scrollFlag33: true,
			tableData33: [], //列表  数组
			currentPage33: 1, //当前页
			pageSize33: 20, //每页  条数
			loadingFlag41: false,
			dataTableEmpty41: false,
			scrollFlag41: true,
			tableData41: [], //列表  数组
			currentPage41: 1, //当前页
			pageSize41: 20, //每页  条数
			loadingFlag42: false,
			dataTableEmpty42: false,
			scrollFlag42: true,
			tableData42: [], //列表  数组
			currentPage42: 1, //当前页
			pageSize42: 20, //每页  条数
		}
	},
	created() {
		this.getPageList11(); //股东变更信息
		this.getPageList12(); //高管变更信息
		this.getPageList13(); //法人变更信息
		this.getPageList21(); //经营异常信息
		this.getPageList22(); //网站异常信息
		this.getPageList31(); //分公司信息
		this.getPageList32(); //投资公司信息
		this.getPageList33(); //招聘信息
		this.getPageList41(); //股权出质信息
		this.getPageList42(); //动产抵押信息 
	},
	methods: {
		titleTabsFn1(item, index) {
			this.titleTabsIndex1 = index;
			if (index == 0) {
				this.getPageList11(); //股东变更
			} else if (index == 1) {
				this.getPageList12(); //法人变更
			} else if (index == 2) {
				this.getPageList13(); //高管变更
			}
		},
		titleTabsFn2(item, index) {
			this.titleTabsIndex2 = index;
			if (index == 0) {
				this.getPageList21(); //经营异常信息
			} else if (index == 1) {
				this.getPageList22(); //网站异常信息
			}
		},
		titleTabsFn3(item, index) {
			this.titleTabsIndex3 = index;
			if (index == 0) {
				this.getPageList31(); //分公司信息
			} else if (index == 1) {
				this.getPageList32(); //投资公司信息
			} else if (index == 2) {
				this.getPageList33(); //招聘信息
			}
		},
		titleTabsFn4(item, index) {
			this.titleTabsIndex4 = index;
			if (index == 0) {
				this.getPageList41(); //股权出质信息
			} else if (index == 1) {
				this.getPageList42(); //动产抵押信息
			}
		},
		//股东变更
		getPageList11() {
			this.loadingFlag11 = true; //loading 显示
			this.dataTableEmpty11 = false;
			this.scrollFlag11 = true;
			this.$provider.get('/wg-ifrRisk/operate/getChangeList', {
				params: {
					companyId: this.$route.query.id,
					changeType: "股东变更",
					current: this.currentPage11++,
					size: this.pageSize11
				}
			}).then(res => {
				this.loadingFlag11 = false; //loading 消失
				this.dataTableEmpty11 = true;
				this.scrollFlag11 = false;
				this.tableData11 = this.tableData11.concat(res.data.records);
				this.titleTabs1[0].value = res.data.total;
			})
		},
		//法人变更
		getPageList12() {
			this.loadingFlag12 = true; //loading 显示
			this.dataTableEmpty12 = false;
			this.scrollFlag12 = true;
			this.$provider.get('/wg-ifrRisk/operate/getChangeList', {
				params: {
					companyId: this.$route.query.id,
					changeType: "法人变更",
					current: this.currentPage12++,
					size: this.pageSize12
				}
			}).then(res => {
				this.loadingFlag12 = false; //loading 消失
				this.dataTableEmpty12 = true;
				this.scrollFlag12 = false;
				this.tableData12 = this.tableData12.concat(res.data.records);
				this.titleTabs1[1].value = res.data.total;
			})
		},
		//高管变更
		getPageList13() {
			this.loadingFlag13 = true; //loading 显示
			this.dataTableEmpty13 = false;
			this.scrollFlag13 = true;
			this.$provider.get('/wg-ifrRisk/operate/getChangeList', {
				params: {
					companyId: this.$route.query.id,
					changeType: "高管变更",
					current: this.currentPage13++,
					size: this.pageSize13
				}
			}).then(res => {
				this.loadingFlag13 = false; //loading 消失
				this.dataTableEmpty13 = true;
				this.scrollFlag13 = false;
				this.tableData13 = this.tableData13.concat(res.data.records);
				this.titleTabs1[2].value = res.data.total;
			})
		},
		//经营异常信息
		getPageList21() {
			this.loadingFlag21 = true; //loading 显示
			this.dataTableEmpty21 = false;
			this.scrollFlag21 = true;
			this.$provider.get('/wg-ifrRisk/operate/getAbnormalList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage21++,
					size: this.pageSize21
				}
			}).then(res => {
				this.loadingFlag21 = false; //loading 消失
				this.dataTableEmpty21 = true;
				this.scrollFlag21 = false;
				this.tableData21 = this.tableData21.concat(res.data.records);
				this.titleTabs2[0].value = res.data.total;
			})
		},
		//网站异常信息
		getPageList22() {
			this.loadingFlag22 = true; //loading 显示
			this.dataTableEmpty22 = false;
			this.scrollFlag22 = true;
			this.$provider.get('/wg-ifrRisk/operate/getWebExceptionList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage22++,
					size: this.pageSize22
				}
			}).then(res => {
				this.loadingFlag22 = false; //loading 消失
				this.dataTableEmpty22 = true;
				this.scrollFlag22 = false;
				this.tableData22 = this.tableData22.concat(res.data.records);
				this.titleTabs2[1].value = res.data.total;
			})
		},
		//分公司信息
		getPageList31() {
			this.loadingFlag31 = true; //loading 显示
			this.dataTableEmpty31 = false;
			this.scrollFlag31 = true;
			this.$provider.get('/wg-ifrRisk/operate/getBranchList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage31++,
					size: this.pageSize31
				}
			}).then(res => {
				this.loadingFlag31 = false; //loading 消失
				this.dataTableEmpty31 = true;
				this.scrollFlag31 = false;
				this.tableData31 = this.tableData31.concat(res.data.records);
				this.titleTabs3[0].value = res.data.total;
			})
		},
		//投资公司信息
		getPageList32() {
			this.loadingFlag32 = true; //loading 显示
			this.dataTableEmpty32 = false;
			this.scrollFlag32 = true;
			this.$provider.get('/wg-ifrRisk/operate/getOutboundInvestmentList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage32++,
					size: this.pageSize32
				}
			}).then(res => {
				this.loadingFlag32 = false; //loading 消失
				this.dataTableEmpty32 = true;
				this.scrollFlag32 = false;
				this.tableData32 = this.tableData32.concat(res.data.records);
				this.titleTabs3[1].value = res.data.total;
			})
		},
		//招聘信息
		getPageList33() {
			this.loadingFlag33 = true; //loading 显示
			this.dataTableEmpty33 = false;
			this.scrollFlag33 = true;
			this.$provider.get('/wg-ifrRisk/operate/getRecruitmentList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage33++,
					size: this.pageSize33
				}
			}).then(res => {
				this.loadingFlag33 = false; //loading 消失
				this.dataTableEmpty33 = true;
				this.scrollFlag33 = false;
				this.tableData33 = this.tableData33.concat(res.data.records);
				this.titleTabs3[2].value = res.data.total;
			})
		},
		//股权出质信息
		getPageList41() {
			this.loadingFlag41 = true; //loading 显示
			this.dataTableEmpty41 = false;
			this.scrollFlag41 = true;
			this.$provider.get('/wg-ifrRisk/operate/getEquityList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage41++,
					size: this.pageSize41
				}
			}).then(res => {
				this.loadingFlag41 = false; //loading 消失
				this.dataTableEmpty41 = true;
				this.scrollFlag41 = false;
				this.tableData41 = this.tableData41.concat(res.data.records);
				this.titleTabs4[0].value = res.data.total;
			})
		},
		//动产抵押信息
		getPageList42() {
			this.loadingFlag42 = true; //loading 显示
			this.dataTableEmpty42 = false;
			this.scrollFlag42 = true;
			this.$provider.get('/wg-ifrRisk/operate/getChattelMortgageList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage42++,
					size: this.pageSize42
				}
			}).then(res => {
				this.loadingFlag42 = false; //loading 消失
				this.dataTableEmpty42 = true;
				this.scrollFlag42 = false;
				this.tableData42 = this.tableData42.concat(res.data.records);
				this.titleTabs4[1].value = res.data.total;
			})
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
