<template>
  <div class="institutional-portrait-info">
		<div class="tab-top">
			<div class="extract-logo-box" v-if="extractName.length>=5" style="font-size: 19px;">
				{{extractName.substring(0,5)}}
			</div>
			<div class="extract-logo-box" v-if="extractName.length<5" style="font-size: 22px;">
				{{extractName}}
			</div>
			<div class="company-info-box">
				<p class="companyName-box">
					<b>{{companyName}}</b>
					<span class="reg-status-box-z" v-show="regstatusName.name!=''&&regstatusName.type=='z'">{{regstatusName.name}}</span>
					<span class="reg-status-box-y" v-show="regstatusName.name!=''&&regstatusName.type=='y'">{{regstatusName.name}}</span>
					<el-tooltip :placement="earlyWarningFilingTime!=null ? 'bottom' : ''" effect="light" v-if="caseStatus != ''">
						<div slot="content">
							<ul class="name-box">
								<li>立案时间：{{Time(earlyWarningFilingTime)}}</li>
							</ul>
						</div>
						<span class="case-names-box" v-if="caseStatus=='已立案'">
							{{caseStatus}} 
							<i class="iconfont icon-jiantouxia1"></i>
						</span>
					</el-tooltip>
					<el-tooltip placement="bottom" effect="light" v-if="historyNameArray.length != 0">
						<div slot="content">
							<ul class="name-box">
								<li v-for="(item,index) in historyNameArray" :key="index">{{item}}</li>
							</ul>
						</div>
						<span class="history-names-box">
							曾用名 <i class="iconfont icon-jiantouxia1"></i>
						</span>
					</el-tooltip>
					<b class="iconfont icon-huo risk-level-box risk-level-gao" v-if="riskLevel == 1"></b>
					<b class="risk-level-box risk-level-gao" v-if="riskLevel == 1">高风险</b>
					<b class="iconfont icon-huo risk-level-box risk-level-zhong" v-if="riskLevel == 2"></b>
					<b class="risk-level-box risk-level-zhong" v-if="riskLevel == 2">中风险</b>
					<b class="iconfont icon-huo risk-level-box risk-level-di" v-if="riskLevel == 3"></b>
					<b class="risk-level-box risk-level-di" v-if="riskLevel == 3">低风险</b>
					<b class="risk-level-box risk-level-wu" v-if="riskLevel == 0">监测中</b>
					<span class="span-box" @click="oneKeyReportLink">一键报告</span>
					<span class="span-box" @click="riskAuditLink">风险审核</span>
					<span class="span-box" @click="issueBtn" v-show="levelParam == 1">下发地市</span>
					<span class="span-box" @click="reportBtn" v-show="levelParam == 1">上报国家</span>
					<span class="span-box" @click="reportBtn" v-show="levelParam == 2">上报省级</span>
				</p>
				<p>
					<span v-if="legalPersonName != ''"><b>法人代表：</b>{{legalPersonName}}</span>
					<span v-if="estiblishTime != ''"><b>注册时间：</b>{{estiblishTime}}</span>
					<span v-if="regCapital != ''"><b>注册资本：</b>{{regCapital}}</span>
					<!-- <span><b>立案时间：</b>{{ Time(earlyWarningFilingTime)}}</span> -->
					<span v-if="actualCapital != null"><b>实缴资本：</b>{{actualCapital}}</span>
					<span v-if="actualCapital == null"><b>实缴资本：</b> - </span>
					<span v-if="regLocation != ''"><b>注册地址：</b>{{regLocation}}</span>
				</p>
				<p>
					<span :class="moreIndex==0 ? 'ellipsis' : ''" v-if="businessScope != ''" style="width: 95%;"><b>经营范围：</b>{{businessScope}}</span>
					<b @click="handleMore" style="float: right;cursor: pointer;color: #409EFF;">{{moreIndex==0 ? '展开' : "收起"}}</b>
				</p>
			</div>
		</div>
		<div class="tab_box">
			<el-tabs >
				<el-tab-pane label="综合风险" :lazy="true">
					<infoWhole></infoWhole>
				</el-tab-pane>
				<el-tab-pane label="基本信息" :lazy="true">
					<infoBasic></infoBasic>
				</el-tab-pane>
				<el-tab-pane label="业务信息" :lazy="true">
					<div :style="{ height: screenHeight + 'px' }">
						<el-scrollbar style="height: 100%;"> 
							<infoBusiness></infoBusiness>
						</el-scrollbar>
					</div>
				</el-tab-pane>
				<el-tab-pane label="舆情信息" :lazy="true">
					<div :style="{ height: screenHeight + 'px' }">
						<el-scrollbar style="height: 100%;"> 
							<infoSentiment></infoSentiment>
						</el-scrollbar>
					</div>
				</el-tab-pane>
				<el-tab-pane label="司法信息" :lazy="true">
					<div :style="{ height: screenHeight + 'px' }">
						<el-scrollbar style="height: 100%;"> 
							<infoJudicial></infoJudicial>
						</el-scrollbar>
					</div>
				</el-tab-pane>
				<el-tab-pane label="经营信息" :lazy="true">
					<div :style="{ height: screenHeight + 'px' }">
						<el-scrollbar style="height: 100%;"> 
							<infoOperate></infoOperate>
						</el-scrollbar>
					</div>
				</el-tab-pane>
				<el-tab-pane label="关联信息" :lazy="true">
					<infoRelation></infoRelation>
				</el-tab-pane>
			</el-tabs>
		</div>
  </div>
</template>

<script>
//组件  需单独的页面   监测预警  详情
import infoWhole from '@/views/monitoring-warning/institutiona-portrait-info/info-whole.vue'
import infoBasic from '@/views/monitoring-warning/institutiona-portrait-info/info-basic.vue'
import infoBusiness from '@/views/monitoring-warning/institutiona-portrait-info/info-business.vue'
import infoSentiment from '@/views/monitoring-warning/institutiona-portrait-info/info-sentiment.vue'
import infoJudicial from '@/views/monitoring-warning/institutiona-portrait-info/info-judicial.vue'
import infoOperate from '@/views/monitoring-warning/institutiona-portrait-info/info-operate.vue'
import infoRelation from '@/views/monitoring-warning/institutiona-portrait-info/info-relation.vue'
export default {
  name: "institutional-portrait-info",
  components:{infoWhole,infoBasic,infoBusiness,infoSentiment,infoJudicial,infoOperate,infoRelation},
  data() {
		return {
			//screenHeight:document.body.clientHeight - 255,
			screenHeight:"",
			companyName: this.$route.query.name,
			extractName: "",
			regstatusName: {
				name:"",type:"y"
			},
			riskLevel:1,
			caseStatus:'',
			earlyWarningFilingTime:null,
			historyNameArray: [],
			legalPersonName:"",
			estiblishTime: "",
			regCapital: "",
			actualCapital: "",
			regLocation: "",
			businessScope: "",
			moreIndex:0,
			levelParam: JSON.parse(sessionStorage.getItem("user_xjfj")).level
		}
  },
  created() {},
  computed:{
		//格式化时间
		Time(){
			return function (val){
				if(val){
					return  val.substring(0,10)	
				}else{
					return  "-"
				}
			}
		}
  },
  mounted() {
		this.getSimpleBaseInfo();////简称    风险等级
		this.getBaseInfoAll();//标题   详情
  },
  methods: {
		//简称    风险等级
		getSimpleBaseInfo(){
			this.$provider.get('/wg-ifrRisk/synthesize/getCompanyAbbr',{
				params:{
					companyId: this.$route.query.id
				}
			}).then(res=>{
				if(res.data!="" && res.data!=null){
					this.extractName = res.data;
				}
			})
		},
		//标题   详情
		getBaseInfoAll(){
			this.$provider.get('/wg-ifrRisk/synthesize/getBaseInfo',{
				params:{
					companyId: this.$route.query.id
				}
			}).then(res=>{
				if(res.data!="" && res.data!=null){ 
					if(res.data.regStatus != null){
						if(res.data.regStatus=="存续" || res.data.regStatus=="迁出" || res.data.regStatus=="正常" || res.data.regStatus=="在营" || res.data.regStatus=="仍注册" || res.data.regStatus=="开业" || res.data.regStatus=="在业" || res.data.regStatus=="登记成立" || res.data.regStatus=="迁往市外"){
							this.regstatusName = {
								name:res.data.regStatus,
								type:"z"
							};
						} else {
							this.regstatusName = {
								name:res.data.regStatus,
								type:"y"
							};
						}	
					} else {
						this.regstatusName = {
							name:"",
							type:"y"
						};
					}
					if(res.data.historyNames){
						this.historyNameArray = JSON.parse(res.data.historyNames);
					}
					if(res.data.caseStatus == 1){
						this.caseStatus = '已立案';
						this.earlyWarningFilingTime = res.data.earlyWarningFilingTime;
					} else if(res.data.caseStatus == 0){
						this.caseStatus = '未立案'; 
						this.earlyWarningFilingTime = res.data.earlyWarningFilingTime;
					} else {
						this.caseStatus = ''; 
						this.earlyWarningFilingTime = null;
					}
					this.legalPersonName = res.data.legalPersonName;
					this.estiblishTime = res.data.estiblishTime;
					this.regCapital = res.data.regCapital;
					this.actualCapital = res.data.actualCapital;
					this.regLocation = res.data.regLocation;
					this.businessScope = res.data.businessScope;
					this.riskLevel = res.data.riskLevel;
				}
			})
		},
		handleMore(){
			if(this.moreIndex == 0){
				this.moreIndex = 1;  
			} else if(this.moreIndex == 1){
				this.moreIndex = 0; 
			}
		},
		//上报
		reportBtn(){
			//地市上报
			if(this.levelParam== 2){
				this.$provider.get('/wg-ifrRisk/cityProcess/preReportProcess',{
					params:{
						companyId: this.$route.query.id
					}
				}).then(res=>{
					if (res.code == 200) {
						this.$message({
							message: '已添加至待上报列表，请在协同联动：待上报页面查看',
							type: 'success'
						});
					} else {
						this.$message({
							message: '上报失败',
							type: 'error'
						});
					}
				})
			} else if(this.levelParam== 1){
				this.$provider.get('/wg-ifrRisk/countrySend/preSendProcessFromMonitor',{
					params:{
						companyId: this.$route.query.id
					}
				}).then(res=>{
					if (res.code == 200) {
						this.$message({
							message: '已添加至待上报列表，请在协同联动：国家上报页面查看',
							type: 'success'
						});
					} else {
						this.$message({
							message: '上报失败',
							type: 'error'
						});
					}
				})  
			}
		},
		//下发
		issueBtn(){
			this.$provider.get('/wg-ifrRisk/provinceProcess/preSendProcess',{
				params:{
					companyId: this.$route.query.id
				}
			}).then(res=>{
				if (res.code == 200) {
					this.$message({
						message: '已添加至待下发列表，请在协同联动：待下发页面查看',
						type: 'success'
					});
				} else {
					this.$message({
						message: '下发失败',
						type: 'error'
					});
				}
			})
		},
		//风险审核
		riskAuditLink(){
			const icardUrl  = this.$router.resolve({
				path: '/institutionalPortraitInfoRiskAudit',
				query: {
					name: this.companyName,
					id: this.$route.query.id
				}
			})
			window.open(icardUrl.href, '_blank');//跳转新页面
		},
		//一键报告
		oneKeyReportLink(){
			const icardUrl  = this.$router.resolve({
			path: '/institutionalPortraitInfoOnekeyReport',
				query: {
					name: this.companyName,
					id: this.$route.query.id
				}
			})
			window.open(icardUrl.href, '_blank');//跳转新页面
		}, 
  },
}
</script>

<style lang="stylus" scoped="scoped"></style>
