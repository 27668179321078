<template>
	<div class="info-judicial">
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">涉诉信息 <span>{{totalItems1}}</span> 条</h4>
					<div class="table-box">
						<el-table border v-loading="loadingFlag1" :data="tableData1" v-el-table-infinite-scroll="getPageList1" infinite-scroll-disabled="scrollFlag1" :row-style="{height:'40px'}" height="352px">
							<el-table-column type="index" label="序号" width="50"></el-table-column>
							<el-table-column prop="publishDate" label="发布时间" width="100" :show-overflow-tooltip="true"></el-table-column>
							<el-table-column prop="title" label="涉案名称" :show-overflow-tooltip="true"></el-table-column>
							<el-table-column prop="caseReason" label="案由" :show-overflow-tooltip="true"></el-table-column>
							<el-table-column prop="caseType" label="案件类型" width="100"></el-table-column>
							<el-table-column prop="courtName" label="法院" :show-overflow-tooltip="true"></el-table-column>
							<template slot="empty">
								<img v-show="dataTableEmpty1" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
							</template>
						</el-table>
					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">违法信息 <span>{{totalItems2}}</span> 条</h4>
					<div class="table-box">
						<el-table border v-loading="loadingFlag2" :data="tableData2" v-el-table-infinite-scroll="getPageList2" infinite-scroll-disabled="scrollFlag2" :row-style="{height:'40px'}" height="352">
							<el-table-column type="index" label="序号" width="50"></el-table-column>
							<el-table-column prop="putDate" label="违法时间" width="100" :show-overflow-tooltip="true"></el-table-column>
							<el-table-column prop="putReason" label="违法原因" :show-overflow-tooltip="true"></el-table-column>
							<template slot="empty">
								<img v-show="dataTableEmpty2" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
							</template>
						</el-table>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">失信被执行人信息
						<ul class="titleTabsBox">
							<li v-for="(item,index) in titleTabs" :key="index" @click="titleTabsFn(item,index)" :class="index == titleTabsIndex ? 'titleTabsActive' : ''">
								<b>{{item.name}}（{{item.value}}</span>）</b>
							</li>
						</ul>
					</h4>
					<div v-show="titleTabsIndex == 0">
						<div class="table-box">
							<el-table border v-loading="loadingFlag3" :data="tableData3" v-el-table-infinite-scroll="getPageList3" infinite-scroll-disabled="scrollFlag3" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="courtName" label="执行法院" width="100" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="caseCode" label="案号" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="publishDate" label="发布日期" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="regDate" label="立案日期" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="performance" label="履行情况" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="gistId" label="执行依据文号" :show-overflow-tooltip="true"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty3" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
					<div v-if="titleTabsIndex == 1">
						<div class="table-box">
							<el-table border v-loading="loadingFlag4" :data="tableData4" v-el-table-infinite-scroll="getPageList4" infinite-scroll-disabled="scrollFlag4" :row-style="{height:'40px'}" height="352">
								<el-table-column type="index" label="序号" width="50"></el-table-column>
								<el-table-column prop="caseCreateTime" label="立案时间" width="100" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="execMoney" label="执行标的(元)"></el-table-column>
								<el-table-column prop="caseCode" label="案号" :show-overflow-tooltip="true"></el-table-column>
								<el-table-column prop="execCourtName" label="执行法院" :show-overflow-tooltip="true"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty4" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
							</el-table>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">行政处罚信息 <span>{{totalItems5}}</span> 条</h4>
					<div class="table-box">
						<el-table border v-loading="loadingFlag5" :data="tableData5" v-el-table-infinite-scroll="getPageList5" infinite-scroll-disabled="scrollFlag5" :row-style="{height:'40px'}" height="352">
							<el-table-column type="index" label="序号" width="50"></el-table-column>
							<el-table-column prop="decisionDate" label="时间" width="100" :show-overflow-tooltip="true"></el-table-column>
							<el-table-column prop="departmentName" label="实施机关" :show-overflow-tooltip="true"></el-table-column>
							<el-table-column prop="punishNumber" label="决定书文号" :show-overflow-tooltip="true"></el-table-column>
							<el-table-column prop="content" label="处罚结果" :show-overflow-tooltip="true"></el-table-column>
							<el-table-column prop="punishmentType" label="处罚详情" :show-overflow-tooltip="true"></el-table-column>
							<template slot="empty">
								<img v-show="dataTableEmpty5" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
							</template>
						</el-table>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import elTableInfiniteScroll from 'el-table-infinite-scroll';
export default {
	name: 'info-judicial',
	directives: {
		'el-table-infinite-scroll': elTableInfiniteScroll
	},
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			titleTabsIndex: 0,
			titleTabs: [
				{name: "失信人信息",value: 0},
				{name: "被执行人信息",value: 0},
			],
			loadingFlag1: false,
			dataTableEmpty1: false,
			scrollFlag1: true,
			tableData1: [], //列表  数组
			currentPage1: 1, //当前页
			pageSize1: 20, //每页  条数
			totalItems1: '0',
			loadingFlag2: false,
			dataTableEmpty2: false,
			scrollFlag2: true,
			tableData2: [], //列表  数组
			currentPage2: 1, //当前页
			pageSize2: 20, //每页  条数
			totalItems2: '0',
			loadingFlag3: false,
			dataTableEmpty3: false,
			scrollFlag3: true,
			tableData3: [], //列表  数组
			currentPage3: 1, //当前页
			pageSize3: 20, //每页  条数
			totalItems3: '0',
			loadingFlag4: false,
			dataTableEmpty4: false,
			scrollFlag4: true,
			tableData4: [], //列表  数组
			currentPage4: 1, //当前页
			pageSize4: 20, //每页  条数
			totalItems4: '0',
			loadingFlag5: false,
			dataTableEmpty5: false,
			scrollFlag5: true,
			tableData5: [], //列表  数组
			currentPage5: 1, //当前页
			pageSize5: 20, //每页  条数
			totalItems5: '0',
		}
	},
	created() {
		this.getPageList1(); //涉诉信息
		this.getPageList2(); //严重违法信息
		this.getPageList3(); //失信人信息
		this.getPageList4(); //执行人信息
		this.getPageList5(); //行政处罚信息
	},
	methods: {
		titleTabsFn(item, index) {
			this.titleTabsIndex = index;
			if (index == 0) {
				this.getPageList3(); //失信人信息
			} else if (index == 1) {
				this.getPageList4(); //被执行人信息
			}
		},
		//涉诉信息
		getPageList1() {
			this.loadingFlag1 = true; //loading 显示
			this.dataTableEmpty1 = false;
			this.scrollFlag1 = true;
			this.$provider.get('/wg-ifrRisk/judicial/getLawsuitList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage1++,
					size: this.pageSize1
				}
			}).then(res => {
				this.loadingFlag1 = false; //loading 消失
				this.dataTableEmpty1 = true;
				this.scrollFlag1 = false;
				this.tableData1 = this.tableData1.concat(res.data.records);
				this.totalItems1 = res.data.total;
			})
		},
		//严重违法信息
		getPageList2() {
			this.loadingFlag2 = true; //loading 显示
			this.dataTableEmpty2 = false;
			this.scrollFlag2 = true;
			this.$provider.get('/wg-ifrRisk/judicial/getIllegalList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage2++,
					size: this.pageSize2
				}
			}).then(res => {
				this.loadingFlag2 = false; //loading 消失
				this.dataTableEmpty2 = true;
				this.scrollFlag2 = false;
				this.tableData2 = this.tableData2.concat(res.data.records);
				this.totalItems2 = res.data.total;
			})
		},
		//失信人信息
		getPageList3() {
			this.loadingFlag3 = true; //loading 显示
			this.dataTableEmpty3 = false;
			this.scrollFlag3 = true;
			this.$provider.get('/wg-ifrRisk/judicial/getDishonestyList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage3++,
					size: this.pageSize3
				}
			}).then(res => {
				this.loadingFlag3 = false; //loading 消失
				this.dataTableEmpty3 = true;
				this.scrollFlag3 = false;
				this.tableData3 = this.tableData3.concat(res.data.records);
				this.titleTabs[0].value = res.data.total;
			})
		},
		//被执行人信息
		getPageList4() {
			this.loadingFlag4 = true; //loading 显示
			this.dataTableEmpty4 = false;
			this.scrollFlag4 = true;
			this.$provider.get('/wg-ifrRisk/judicial/getExecutorList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage4++,
					size: this.pageSize4
				}
			}).then(res => {
				this.loadingFlag4 = false; //loading 消失
				this.dataTableEmpty4 = true;
				this.scrollFlag4 = false;
				this.tableData4 = this.tableData4.concat(res.data.records);
				this.titleTabs[1].value = res.data.total;
			})
		},
		//行政处罚信息
		getPageList5() {
			this.loadingFlag5 = true; //loading 显示
			this.dataTableEmpty5 = false;
			this.scrollFlag5 = true;
			this.$provider.get('/wg-ifrRisk/judicial/getPunishmentList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage5++,
					size: this.pageSize5
				}
			}).then(res => {
				this.loadingFlag5 = false; //loading 消失
				this.dataTableEmpty5 = true;
				this.scrollFlag5 = false;
				this.tableData5 = this.tableData5.concat(res.data.records);
				this.totalItems5 = res.data.total;
			})
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
