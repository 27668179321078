<template>
	<div class="table-box">
		<el-table :data="data" border :row-style="{height:'40px'}" :height="450">
			<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
			<el-table-column v-for="(item,ind) in columns" :key="ind" :prop="item.field" :label="item.name" :show-overflow-tooltip="true"></el-table-column>
			<template slot="empty">
				<img class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
			</template>
		</el-table>
	</div>
</template>

<script>
export default {
	name: "table-box",
	props: {
		columns: {
			type: Array,
			default () {
				return [];
			}
		},
		data: {
			type: Array,
			default () {
				return [];
			}
		},
		currentPage: {
			type: Number,
			requires: true
		},
		pageSize: {
			type: Number,
			requires: true
		},
		total: {
			type: Number,
			requires: true
		},
		name: {
			type: String,
			requires: true
		}
	},
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
		}
	},
	created() {},
	methods: {
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
