<template>
  <div class="my-enterprise">
		<div v-show="!dataEmpty">
			<div v-if="difference == 'YW'">
				<div class="opinion-list" v-for="(item,index) in data" :key="index">
					<h5 @click="handleEdit(item, index)">
						<span class="index-num">{{index + 1}}</span>
						<span class="opinion-title ellipsis" v-html="item.title">{{item.title}}</span>
					</h5>
					<div class="opinion-text">
						<p class="ellipsisTwo" v-html="item.abstracts">{{item.abstracts}}</p>
					</div>
					<div class="opinion-bottom">
						<div class="article-source">
							<span v-if="item.type == '4'"><i class="iconfont icon-xinwen"></i>新闻</span>
							<span v-if="item.type == '9'"><i class="iconfont icon-weibo2"></i>微博</span>
							<span v-if="item.type == '10'"><i class="iconfont icon-APP"></i>APP</span>
							<span v-if="item.type == '5'"><i class="iconfont icon-wangzhan"></i>博客</span>
							<span v-if="item.type == '8'"><i class="iconfont icon-weixin1"></i>微信</span>
							<span v-if="item.type == '6'"><i class="iconfont icon-icon_luntan-mian"></i>论坛</span>
						</div>
						<div class="article-time">发布时间：{{item.pubtime}}</div>
						<div class="article-tags"><b>风险点：</b><span>{{item.tagName}}</span></div>
						<div class="article-href"><a :href="item.url" target="_blank" title="源链接"><i class="iconfont icon-lianjie1"></i></a></div>
					</div>
				</div>
			</div>
			<div v-if="difference == 'YQ'">
				<div class="opinion-list" v-for="(item,index) in data" :key="index">
					<h5 @click="handleEdit(item, index)">
						<span class="index-num">{{index + 1}}</span>
						<span class="opinion-title ellipsis" v-html="item.title">{{item.title}}</span>
					</h5>
					<div class="opinion-text">
						<p class="ellipsisTwo" v-html="item.content">{{item.content}}</p>
					</div>
					<div class="opinion-bottom">
						<div class="article-source">
							<span v-if="item.source_type == '4'"><i class="iconfont icon-xinwen"></i>新闻</span>
							<span v-if="item.source_type == '9'"><i class="iconfont icon-weibo2"></i>微博</span>
							<span v-if="item.source_type == '10'"><i class="iconfont icon-APP"></i>APP</span>
							<span v-if="item.source_type == '5'"><i class="iconfont icon-wangzhan"></i>博客</span>
							<span v-if="item.source_type == '8'"><i class="iconfont icon-weixin1"></i>微信</span>
							<span v-if="item.source_type == '6'"><i class="iconfont icon-icon_luntan-mian"></i>论坛</span>
						</div>
						<div class="article-time">发布时间：{{item.pubtime}}</div>
						<div class="article-href"><a :href="item.source_url" target="_blank" title="源链接"><i class="iconfont icon-lianjie1"></i></a></div>
					</div>
				</div>
				</div>
		</div>
		<div v-show="dataEmpty">
			<img :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;">
		</div>
		<!-- 详情 -->
		<el-dialog title="" :visible.sync="dialogVisible" width="60%" class="modal-box">
			<div class="modal-body-box" v-loading="tableloading">
				<div class="ellipsis modal-title" :title="dialogVisibleInfo.title" v-html="dialogVisibleInfo.title">{{dialogVisibleInfo.title}}</div>
				<div class="modal-content">
					<span v-if="dialogVisibleInfo.source_type == 4">信息源：<b>新闻</b></span>
					<span v-if="dialogVisibleInfo.source_type == 9">信息源：<b>微博</b></span>
					<span v-if="dialogVisibleInfo.source_type == 10">信息源：<b>APP</b></span>
					<span v-if="dialogVisibleInfo.source_type == 5">信息源：<b>网站</b></span>
					<span v-if="dialogVisibleInfo.source_type == 8">信息源：<b>微信</b></span>
					<span v-if="dialogVisibleInfo.source_type == 6">信息源：<b>论坛</b></span>
					<span v-if="dialogVisibleInfo.source_type=='9' || dialogVisibleInfo.source_type == '8'">
						<span v-if="dialogVisibleInfo.site != ''">作者：<b>{{dialogVisibleInfo.site}}</b></span>
					</span>
					<span v-if="dialogVisibleInfo.source_type!='9' && dialogVisibleInfo.source_type != '8'">
						<span v-if="dialogVisibleInfo.site != ''">站点：<b>{{dialogVisibleInfo.site}}</b></span>
					</span>
					<span v-if="dialogVisibleInfo.pubtime != ''">发布时间：<b>{{dialogVisibleInfo.pubtime}}</b></span>
					<span v-if="dialogVisibleInfo.source_url != ''">
						<a :href="dialogVisibleInfo.source_url" target="_blank" title="源链接">
							<i class="iconfont icon-lianjie1"></i>
						</a>
					</span>
				</div>
				<div class="modal-article">
					<div style="width: 100%;height: 600px;">
						<el-scrollbar style="height: 100%;width:100%">
							<span v-html="dialogVisibleInfo.content">{{dialogVisibleInfo.content}}</span>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</el-dialog>
  </div>
</template>

<script>
export default {
  name: 'my-enterprise',
  props:{
    data:{
			type:Array,
    },
		dataEmpty:{
			type:Boolean,
		},
    difference:{
			type:String,
    },
  },
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			dialogVisible: false, //是否打开详情弹框
			tableloading: false,
			dialogVisibleInfo: {
				title: "",
				source_type: "",
				site:"",
				pubtime: "",
				source_url:'',
				content: "",
			}
		}
  },
  created() {},
  methods: {
		//详情  弹框打开
		handleEdit(result, index) {
			this.dialogVisibleInfo.title = "";
			this.dialogVisibleInfo.source_type = "";
			this.dialogVisibleInfo.pubtime = "";
			this.dialogVisibleInfo.source_url = "";
			this.dialogVisibleInfo.content = "";
			this.dialogVisible = true; //打开弹框
			if(this.difference == 'YW'){
				this.getDetailInfo(result.id);
			} else if(this.difference == 'YQ'){
				this.dialogVisibleInfo.title = result.title;
				this.dialogVisibleInfo.source_type = result.source_type;
				this.dialogVisibleInfo.site = result.site;
				this.dialogVisibleInfo.pubtime = result.pubtime;
				this.dialogVisibleInfo.source_url = result.source_url;
				this.dialogVisibleInfo.content = result.content;
			}
		},
		//业务信息  详情
		getDetailInfo(result){
			this.tableloading = true;
			this.$provider.get('/wg-ifrRisk/business/getDetailInfo',{
				params:{
					id: result
				}
			}).then(res=>{
				this.tableloading = false;
				this.dialogVisibleInfo.title = res.data.title;
				this.dialogVisibleInfo.source_type = res.data.source_type;
				this.dialogVisibleInfo.site = res.data.site;
				this.dialogVisibleInfo.pubtime = res.data.pubtime;
				this.dialogVisibleInfo.source_url = res.data.source_url;
				this.dialogVisibleInfo.content = res.data.content;
			})
		}
  }
}
</script>

<style lang="stylus" scoped="scoped"></style>
