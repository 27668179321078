<template>
	<div class="info-sentiment">
		<el-row :gutter="10">
			<el-col :span="16">
				<div class="content-box" style="position: relative;">
					<h4 class="title-h4">相关信息 <span>{{totalItems}}</span> 条</h4>
					<el-select v-model="timeSent" size="mini" clearable @change="timeChange" placeholder="请选择" style="width: 100px;position: absolute;top: 5px;right: 10px;">
						<el-option label="近一月" value="1"></el-option>
						<el-option label="近三月" value="3"></el-option>
						<el-option label="近半年" value="6"></el-option>
						<el-option label="近一年" value="12"></el-option>
					</el-select>
					<div v-loading="loadingFlag1">
						<div class="enterprise-box" v-infinite-scroll="getPageList" style="height: 415px;">
							<myEnterprise :data="tableData" :dataEmpty="showHide1" difference="YQ"></myEnterprise>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="content-box">
					<h4 class="title-h4">舆情热词</h4>
					<div v-loading="loadingFlag4">
						<div v-show="!showHide4" style="width:100%;">
							<div id="PublicSentimentHotWords" style="height: 415px;"></div>
						</div>
						<div v-show="showHide4" style="height: 415px;overflow: hidden;">
							<img :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;">
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="10">
			<el-col :span="16">
				<div class="content-box">
					<h4 class="title-h4">变化趋势</h4>
					<div v-loading="loadingFlag2">
						<div v-show="!showHide2">
							<div id="publicSentimentTrend" style="height: 300px;"></div>
						</div>
						<div v-show="showHide2" style="height: 300px;overflow: hidden;">
							<img :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;">
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="content-box">
					<h4 class="title-h4">舆情指数</h4>
					<div v-loading="loadingFlag3">
						<div v-show="!showHide3">
							<div id="sentimentIndexBox" style="height: 300px;overflow: hidden;"></div>
						</div>
						<div v-show="showHide3" style="height: 300px;overflow: hidden;">
							<img :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;">
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import myEnterprise from '../../../components/my-enterprise.vue'
import {echartsPieRose,echartsLineArea,echartsScatter,echartsGaugePie} from "../../../common/js/chart.js"
export default {
	name: 'info-sentiment',
	components: {
		myEnterprise
	},
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			loadingFlag1: false,
			loadingFlag2: false,
			loadingFlag3: false,
			loadingFlag4: false,
			showHide1: false,
			showHide2: false,
			showHide3: false,
			showHide4: false,
			timeSent: "3",
			tableData: [], //列表  数组
			currentPage: 0, //当前页
			pageSize: 10, //每页  条数
			totalItems: 0, //列表 总数
		}
	},
	created() {},
	mounted() {
		this.getPublicSentimentTrend(); //舆情变化趋势
		//this.getFindpolarity(); //情感分布
		this.getSentimentIndex(); //负面舆情指数
		this.getPublicSentimentHotWords(); //热词
	},
	methods: {
		timeChange() {
			this.tableData = [];
			this.currentPage = 0;
			this.getPageList(); //加载列表
			this.getPublicSentimentTrend(); //舆情变化趋势
			//this.getFindpolarity(); //情感分布
			this.getSentimentIndex(); //负面舆情指数
			this.getPublicSentimentHotWords(); //热词
		},
		//加载列表
		getPageList() {
			this.loadingFlag1 = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/sentiment/getSentimentList', {
				params: {
					companyId: this.$route.query.id,
					month: this.timeSent,
					current: this.currentPage += 1,
					size: this.pageSize
				}
			}).then(res => {
				this.loadingFlag1 = false; //loading 消失
				if (res.data.total != 0) {
					for (var i = 0; i < res.data.list.length; i++) {
						this.tableData.push(res.data.list[i]);
					}
					this.totalItems = res.data.total;
					this.showHide1 = false;
				} else {
					this.showHide1 = true;
				}
			})
		},
		//舆情变化趋势
		getPublicSentimentTrend() {
			this.loadingFlag2 = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/sentiment/getSentimentTrend', {
				params: {
					companyId: this.$route.query.id,
					month: this.timeSent,
				}
			}).then(res => {
				this.loadingFlag2 = false; //loading 消失
				let d = res.data;
				let _this = this;
				if (JSON.stringify(d) != "{}") {
					setTimeout(function() {
						let arr = [];
						for (let i in d) {
							arr.push({
								time: i,
								value: d[i]
							})
						}
						let time = [],
							data = [];
						let ary = _this.sortData(arr);
						for (let k = 0; k < ary.length; k++) {
							time.push(ary[k].time);
							data.push(ary[k].value);
						}
						echartsLineArea("publicSentimentTrend", time, data, '#fc8aa6');
					}, 200);
					this.showHide2 = false;
				} else {
					this.showHide2 = true;
				}
			})
		},
		//数组  对象  排序
		sortData(data) {
			return data.sort(function(a, b) {
				//这里的time要根据本身的数据来，后面的正则匹配也要根据自己数据来
				return Date.parse(a.time.replace(/-/g, "/")) - Date.parse(b.time.replace(/-/g, "/"));
			});
		},
		//情感分布
		getFindpolarity() {
			this.loadingFlag3 = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/sentiment/getSentimentPolarity', {
				params: {
					companyId: this.$route.query.id,
					month: this.timeSent,
				}
			}).then(res => {
				this.loadingFlag3 = false; //loading 消失
				if (res.data.zx != 0 || res.data.zl != 0 || res.data.fx != 0) {
					setTimeout(function() {
						let legendData = ["正面", "中立", "负面"],
							seriesDataa = [
								{
									name: "正面",
									value: res.data.zx,
								},
								{
									name: "中立",
									value: res.data.zl,
								},
								{
									name: "负面",
									value: res.data.fx,
								}
							],
							colorList = ["#12DC9A", "#FFCC00", "#DD3333"]
						echartsPieRose("findpolarityBox", seriesDataa, legendData, colorList);
					}, 200);
					this.showHide3 = false;
				} else {
					this.showHide3 = true;
				}
			})
		},
		//热词
		getPublicSentimentHotWords() {
			this.loadingFlag4 = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/sentiment/getSentimentHotWords', {
				params: {
					companyId: this.$route.query.id,
					month: this.timeSent,
				}
			}).then(res => {
				this.loadingFlag4 = false; //loading 消失
				let da = res.data.list[0];
				if (da.length != 0) {
					setTimeout(function() {
						let plantCap = [];
						if (da.length < 10) {
							for (let i = 0; i < da.length; i++) {
								let daObj = {
									name: da[i].keyWord,
									value: da[i].wight
								};
								plantCap.push(daObj);
							}
						} else {
							for (let i = 0; i < 10; i++) {
								let daObj = {
									name: da[i].keyWord,
									value: da[i].wight
								};
								plantCap.push(daObj);
							}
						}
						echartsScatter("PublicSentimentHotWords", plantCap);
					}, 200);
					this.showHide4 = false;
				} else {
					this.showHide4 = true;
				}
			})
		},
		//负面舆情指数
		getSentimentIndex() {
			this.loadingFlag3 = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/sentiment/getSentimentIndex', {
				params: {
					companyId: this.$route.query.id,
				}
			}).then(res => {
				this.loadingFlag3 = false; //loading 消失
				if (res.data != "") {
					echartsGaugePie("sentimentIndexBox", res.data);
					this.showHide3 = false;
				} else {
					this.showHide3 = true;
				}
			})
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
