<template>
	<div class="my-jbxx">
		<el-row :gutter="10">
			<el-col :span="24">
				<div class="content-box">
					<h4 class="title-h4">工商信息</h4>
					<ul class="gs-info-box">
						<li>
							<span>法人代表：</span>
							<span>{{BusinessInfo.legalPersonName}}</span>
						</li>
						<li>
							<span>注册日期：</span>
							<span>{{BusinessInfo.estiblishTime}}</span>
						</li>
						<li>
							<span>行业：</span>
							<span>{{BusinessInfo.industry}}</span>
						</li>
						<li>
							<span>注册资本：</span>
							<span>{{BusinessInfo.regCapital}}</span>
						</li>
						<li>
							<span>实缴资本：</span>
							<span>{{BusinessInfo.actualCapital}}</span>
						</li>
						<li>
							<span>公司类型：</span>
							<span>{{BusinessInfo.orgType}}</span>
						</li>
						<li>
							<span>经营状态：</span>
							<span>{{BusinessInfo.regStatus}}</span>
						</li>
						<li>
							<span>登记机关：</span>
							<span>{{BusinessInfo.regInstitute}}</span>
						</li>
						<li>
							<span>营业期限：</span>
							<span>{{BusinessInfo.fromTime}} 至 {{BusinessInfo.toTime}}</span>
						</li>
						<li>
							<span>工商注册号：</span>
							<span>{{BusinessInfo.regNumber}}</span>
						</li>
						<li class="ellipsis" style="width: 66%;">
							<span>注册地址：</span>
							<span>{{BusinessInfo.regLocation}}</span>
						</li>
						<li class="ellipsis" style="width: 100%;">
							<span>网址：</span>
							<span v-if="BusinessInfo.websiteList.length != 0">
								<a v-for="(item,index) in BusinessInfo.websiteList" :key="index" :href="'http://'+item" target="_blank" class="color-m">
									<span v-if="index == 0">{{item}}</span>
									<span v-else>，{{item}}</span>
								</a>
							</span>
							<span v-else>
								暂无
							</span>
						</li>
						<li class="ellipsis" style="width: 100%;">
							<span>经营范围：</span>
							<el-tooltip placement="top-start" v-if="BusinessInfo.businessScope != ''">
								<div slot="content" style="width: 1000px;">{{BusinessInfo.businessScope}}</div>
								<span>{{BusinessInfo.businessScope}}</span>
							</el-tooltip>
						</li>
					</ul>
				</div>
				<div class="content-box">
					<h4 class="title-h4">股东信息 <span>{{totalItems}}</span> 条</h4>
					<div class="table-box">
						<el-table border v-loading="loadingFlag" empty-text="加载中..." :data="tableData" :height="490" :row-style="{height:'40px'}">
							<el-table-column type="index" label="序号" width="50"></el-table-column>
							<el-table-column prop="companyName" label="股东（发起人）"></el-table-column>
							<el-table-column prop="percent" label="持股比例"></el-table-column>
							<el-table-column prop="amount" label="认缴出资额"></el-table-column>
							<el-table-column prop="investTime" label="出资日期"></el-table-column>
							<template slot="empty">
								<img v-show="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
							</template>
						</el-table>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'my-jbxx',
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			BusinessInfo: {
				legalPersonName: "",
				industry: "",
				regCapital: "",
				actualCapital: "",
				orgType: "",
				regStatus: "",
				estiblishTime: "",
				regNumber: "",
				regInstitute: "",
				websiteList: [],
				historyNames: "",
				regLocation: "",
				businessScope: "",
			},
			loadingFlag: false,
			dataTableEmpty: false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 1000, //每页  条数
			totalItems: 0, //列表 总数
		}
	},
	created() {
		this.getBusinessInfoByCompany(); //工商信息
		this.getShareholderInfoByCompany(); //股东信息
	},
	methods: {
		//工商信息
		getBusinessInfoByCompany() {
			this.$provider.get('/wg-ifrRisk/baseinfo/getBusiInfo', {
				params: {
					companyId: this.$route.query.id
				}
			}).then(res => {
				this.BusinessInfo.legalPersonName = res.data.legalPersonName;
				this.BusinessInfo.industry = res.data.industry;
				this.BusinessInfo.regCapital = res.data.regCapital;
				this.BusinessInfo.actualCapital = res.data.actualCapital;
				this.BusinessInfo.orgType = res.data.orgType;
				this.BusinessInfo.regStatus = res.data.regStatus;
				this.BusinessInfo.estiblishTime = res.data.estiblishTime;
				this.BusinessInfo.regNumber = res.data.regNumber;
				this.BusinessInfo.fromTime = res.data.fromTime;
				this.BusinessInfo.toTime = res.data.toTime;
				this.BusinessInfo.regInstitute = res.data.regInstitute;
				if (res.data.websiteList) {
					this.BusinessInfo.websiteList = JSON.parse(res.data.websiteList);
				}
				this.BusinessInfo.regLocation = res.data.regLocation;
				this.BusinessInfo.businessScope = res.data.businessScope;
			})
		},
		//股东信息
		getShareholderInfoByCompany() {
			this.loadingFlag = true; //loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-ifrRisk/baseinfo/getShareholderList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage,
					size: this.pageSize
				}
			}).then(res => {
				this.loadingFlag = false; //loading 消失
				this.dataTableEmpty = true;
				for (var i = 0; i < res.data.records.length; i++) {
					this.tableData.push(res.data.records[i]);
				}
				this.totalItems = res.data.total;
			})
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
