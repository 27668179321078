<template>
	<div class="info-relation">
		<el-row :gutter="10">
			<el-col :span="16">
				<div class="content-box">
					<iframe :src="`${baseUrl}chart/guanxi.html`" style="width: 100%;height: 790px" frameborder="0" scrolling="no" id="bdIframe" ref="mainIframe" name="mainIframe"></iframe>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="content-box">
					<h4 class="title-h4">关联问题企业（ <span>{{totalItems}}</span> 家）</h4>
					<div class="table-box">
						<el-table border v-loading="loadingFlag" :data="tableData" empty-text="加载中..." :row-style="{height:'40px'}" :height="765">
							<el-table-column type="index" label="序号" width="50"></el-table-column>
							<el-table-column prop="lineInfo" label="关联企业名称" :show-overflow-tooltip="true"></el-table-column>
							<el-table-column prop="lineType" label="关联关系类型" width="150"></el-table-column>
							<el-table-column prop="requestDesc" label="问题描述" :show-overflow-tooltip="true"></el-table-column>
								<template slot="empty">
									<img v-show="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
								</template>
						</el-table>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'info-relation',
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			loadingFlag: false,
			dataTableEmpty: true,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 10000, //每页  条数
			totalItems: 0, //列表 总数
		}
	},
	mounted() {
		const objData = window.frames['mainIframe']; // 获得对应iframe的window对象
		objData.nameData = this.$route.query.name;
		objData.idData = this.$route.query.id;
		objData.urlData = this.$pulick.urlStr;

		//window.addEventListener("message", this.handleMessage)；//父组件  接值
	},
	created() {
		this.getRelationProblemList();
	},
	methods: {
		//关联问题企业
		getRelationProblemList() {
			this.loadingFlag = true;
			this.dataTableEmpty = false;
			this.$provider.get('/wg-ifrRisk/relation/getRelationProblemList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPage,
					size: this.pageSize
				}
			}).then(res => {
				this.loadingFlag = false;
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		/* //父组件  接值
		handleMessage (event) {
			var data = event.data;
			switch (data.cmd) {
			case 'returnInfo':
				this.tableloading = data.params.info
				console.log(this.tableloading)
				break
			}
		}, */
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
