<template>
	<div class="info-whole">
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="content-box">
		 		<h4 class="title-h4">综合风险</h4>
					<el-row :gutter="0">
						<el-col :span="18">
							<div id="chartsRadarBox" style="height: 450px;"></div>
						</el-col>
						<el-col :span="6">
							<p class="randar-title">
								<b>风险指数</b>
								<span>{{riskNum}}</span>
							</p>
						</el-col>
					</el-row>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">风险标签</h4>
					<div class="risk-label-box" v-if="LabelYewu.length!=0 || LabelYuqing.length!=0 || LabelSifa.length!=0 || LabelYunying.length!=0 || LabelZhoubian.length!=0">
						<el-scrollbar style="height: 100%;width:100%">
							<el-collapse v-model="activeLabel">
								<el-collapse-item title="" name="1" v-if="LabelYewu.length != 0">
									<template slot="title">
										<i class="iconfont icon-shengqian" style="margin:0 10px;"></i>业务风险
									</template>
									<div class="form-group" v-for="(item,index) in LabelYewu" :key="index" @click="getLabelInfo(item,index)">
										<span class="label-index">{{index+1}}</span>
										{{item.tagName}}
										<span class="label-num">{{item.count}}</span>
									</div>
								</el-collapse-item>
								<el-collapse-item title="" name="2" v-if="LabelYuqing.length != 0">
									<template slot="title">
										<i class="iconfont icon-shengqian" style="margin:0 10px;"></i>舆情风险
									</template>
									<div class="form-group" v-for="(item,index) in LabelYuqing" :key="index" @click="getLabelInfo(item,index)">
										<span class="label-index">{{index+1}}</span>
										{{item.tagName}}
										<span class="label-num">{{item.count}}</span>
									</div>
								</el-collapse-item>
								<el-collapse-item title="" name="3" v-if="LabelSifa.length != 0">
									<template slot="title">
										<i class="iconfont icon-shengqian" style="margin:0 10px;"></i>司法风险
									</template>
									<div class="form-group" v-for="(item,index) in LabelSifa" :key="index" @click="getLabelInfoF(item,index)">
										<span class="label-index">{{index+1}}</span>
										{{item.tagName}}
										<span class="label-num">{{item.count}}</span>
									</div>
								</el-collapse-item>
								<el-collapse-item title="" name="4" v-if="LabelYunying.length != 0">
									<template slot="title">
										<i class="iconfont icon-shengqian" style="margin:0 10px;"></i>经营风险
									</template>
									<div class="form-group" v-for="(item,index) in LabelYunying" :key="index" @click="getLabelInfoF(item,index)">
										<span class="label-index">{{index+1}}</span>
										{{item.tagName}}
										<span class="label-num">{{item.count}}</span>
									</div>
								</el-collapse-item>
								<el-collapse-item title="" name="5" v-if="LabelZhoubian.length != 0">
									<template slot="title">
										<i class="iconfont icon-shengqian" style="margin:0 10px;"></i>关联风险
									</template>
									<div class="form-group" v-for="(item,index) in LabelZhoubian" :key="index" @click="getLabelInfoF(item,index)">
										<span class="label-index">{{index+1}}</span>
										{{item.tagName}}
										<span class="label-num">{{item.count}}</span>
									</div>
								</el-collapse-item>
							</el-collapse>
						</el-scrollbar>
					</div>
					<div class="risk-label-box" v-else>
						<img class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">风险变化趋势</h4>
					<div v-show="!showHide" id="chartsLineBox" style="height: 270px;"></div>
					<div v-show="showHide" style="width: 100%;height: 270px;overflow: hidden;">
						<img :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 90px auto;">
					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">大事记</h4>
					<div v-show="chronicleEvents.length != 0" style="width: 100%;height: 270px;overflow: hidden;">
						<vue-seamless-scroll :data="chronicleEvents" :class-option="classOption" id="chronicleEventsContent">
							<ul class="chronicle-events-box">
								<li v-for="(item,index) in chronicleEvents" :key="index">
									<div class="content-box">
										<el-tooltip :placement="index%2==0 ? 'top' : 'bottom'" effect="light">
											<div slot="content">{{item.message}}</div>
											<p>{{item.message}}</p>
										</el-tooltip>
									</div>
									<p class="time-box">{{item.date}}</p>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
					<div v-show="chronicleEvents.length == 0" style="width: 100%;height: 270px;overflow: hidden;">
						<img :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 90px auto;">
					</div>
				</div>
			</el-col>
		</el-row>
		<!-- 舆情 业务等 详情 列表-->
		<el-dialog :title="titleLabelY" :visible.sync="labelVisibleY" width="80%">
			<div class="enterprise-box" style="height: 525px;">
				<div class="opinion-list" v-for="(item,index) in tableDataY" :key="index">
					<h5>
						<span class="index-num">{{typeIndexY(index)}}</span>
						<span class="opinion-title ellipsis" v-html="item.title">{{item.title}}</span>
					</h5>
					<div class="opinion-text">
						<p class="ellipsisTwo" v-html="item.abstracts">{{item.abstracts}}</p>
					</div>
					<div class="opinion-bottom">
						<div class="article-source">
							<span v-if="item.type == '4'"><i class="iconfont icon-xinwen"></i>新闻</span>
							<span v-if="item.type == '9'"><i class="iconfont icon-weibo2"></i>微博</span>
							<span v-if="item.type == '10'"><i class="iconfont icon-APP"></i>APP</span>
							<span v-if="item.type == '5'"><i class="iconfont icon-wangzhan"></i>博客</span>
							<span v-if="item.type == '8'"><i class="iconfont icon-weixin1"></i>微信</span>
							<span v-if="item.type == '6'"><i class="iconfont icon-icon_luntan-mian"></i>论坛</span>
							<span v-if="item.type == '0'"><i class="iconfont icon-qitalaiyuan"></i>其他</span>
						</div>
						<div class="article-time">发布时间：{{item.pubtime}}</div>
						<div class="article-tags"><b>风险点：</b><span>{{item.tagName}}</span></div>
						<div class="article-href">
							<a :href="item.url" target="_blank" title="源链接">
								<i class="iconfont icon-lianjie1"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
			<el-pagination @current-change="handleCurrentChangeY" :total="totalItemsY" :current-page="currentPageY" :page-size="pageSizeY" layout="total, prev, pager, next, jumper"></el-pagination>
		</el-dialog>
		<!-- 司法 经营等 详情 表格 -->
		<el-dialog :title="titleLabelF" :visible.sync="labelVisibleF" width="80%">
			<div class="contentBox">
				<myTable :data='tableDataF' :currentPage='currentPageF' :pageSize='pageSizeF' :columns='tableColumnsF' :name="titleLabelF"></myTable>
				<el-pagination @current-change="handleCurrentChangeF" :total="totalItemsF" :current-page="currentPageF" :page-size="pageSizeF" layout="total, prev, pager, next, jumper"></el-pagination>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import myTable from '@/components/my-table.vue'
import {echartsRadar,echartsLineAreaLabel} from "../../../common/js/chart.js"
export default {
	name: 'info-whole',
	components: {vueSeamlessScroll,myTable},
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			showHide: false,
			riskNum: "",
			activeLabel: ['1', '2', '3', '4', '5'],
			LabelSifa: [],
			LabelYewu: [],
			LabelYunying: [],
			LabelYuqing: [],
			LabelZhoubian: [],
			chronicleEvents: [], //大事记
			labelVisibleY: false, //业务、舆情
			titleLabelY: '',
			labelParamY: {},
			tableDataY: [], //列表  数组
			currentPageY: 1, //当前页
			pageSizeY: 5, //每页  条数
			totalItemsY: 0, //列表 总数
			labelVisibleF: false, //司法、经营、关联
			titleLabelF: '',
			labelParamF: {},
			tableDataF: [], //列表  数组
			currentPageF: 1, //当前页
			pageSizeF: 10, //每页  条数
			totalItemsF: 0, //列表 总数
			tableColumnsF: [],
			tableColumns: {
				"行政处罚": [
					{name: '时间',field: 'decisionDate'},
					{name: '实施机关',field: 'departmentName'},
					{name: '决定文书号',field: 'punishNumber'},
					{name: '处罚结果',field: 'content'},
					{name: '处罚详情',field: 'punishmentType'},
				],
				"涉诉次数多": [
					{name: '发布时间',field: 'publishDate'},
					{name: '涉案名称',field: 'title'},
					{name: '案由',field: 'caseReason'},
					{name: '案件类型',field: 'caseType'},
					{name: '法院',field: 'courtName'},
				],
				"严重违法": [
					{name: '违法时间',field: 'putDate'},
					{name: '违法原因',field: 'putReason'},
				],
				"企业、法人或高管被列为失信人": [
					{name: '执行法院',field: 'courtName'},
					{name: '案号',field: 'caseCode'},
					{name: '发布日期',field: 'publishDate'},
					{name: '立案日期',field: 'regDate'},
					{name: '履行情况',field: 'performance'},
					{name: '执行依据文号',field: 'gistId'},
				],
				"企业被列为强制被执行人": [
					{name: '立案时间',field: 'caseCreateTime'},
					{name: '执行标的（元）',field: 'execMoney'},
					{name: '案号',field: 'caseCode'},
					{name: '执行法院',field: 'execCourtName'},
				],
				"股东变更频繁": [
					{name: '变更时间',field: 'changeTime'},
					{name: '变更前',field: 'contentBefore'},
					{name: '变更后',field: 'contentAfter'},
				],
				"高管变更频繁": [
					{name: '变更时间',field: 'changeTime'},
					{name: '变更前',field: 'contentBefore'},
					{name: '变更后',field: 'contentAfter'},
				],
				"法人变更频繁": [
					{name: '变更时间',field: 'changeTime'},
					{name: '变更前',field: 'contentBefore'},
					{name: '变更后',field: 'contentAfter'},
				],
				"短期大量对外投资": [
					{name: '被投资公司名称',field: 'companyName'},
					{name: '投资金额（万）',field: 'decimalAmount'},
				],
				"短期招聘大量人员": [
					{name: '发布时间',field: 'publishTime'},
					{name: '招聘职位',field: 'jobName'},
					{name: '薪资',field: 'jobSalary'},
					{name: '工作经验',field: 'jobExperience'},
					{name: '招聘人数',field: 'recruitNumber'},
				],
				"短期大量开设子公司": [
					{name: '注册时间',field: 'estiblishTime'},
					{name: '分公司名称',field: 'companyName'},
					{name: '注册资本',field: 'regCapital'},
				],
				"设立有效股权出质": [
					{name: '登记时间',field: 'regDate'},
					{name: '出质人',field: 'pledgor'},
					{name: '质权人',field: 'pledgee'},
					{name: '出质股权数',field: 'equityAmount'},
				],
				"动产抵押": [
					{name: '登记时间',field: 'regDate'},
					{name: '登记编号',field: 'regNum'},
					{name: '被担保债权类型',field: 'mtType'},
					{name: '被担保债权数额',field: 'mtAmount'},
					{name: '登记机关',field: 'regDepartment'},
				],
				"网站打不开": [
					{name: '网址',field: 'webUrl'},
					{name: '是否备案',field: 'isRecord'},
					{name: '是否在境外',field: 'isOverseas'},
					{name: '运行状态',field: 'isFanwen'},
				],
				"网站未备案": [
					{name: '网址',field: 'webUrl'},
					{name: '是否备案',field: 'isRecord'},
					{name: '是否在境外',field: 'isOverseas'},
					{name: '运行状态',field: 'isFanwen'},
				],
				"网站在境外": [
					{name: '网址',field: 'webUrl'},
					{name: '是否备案',field: 'isRecord'},
					{name: '是否在境外',field: 'isOverseas'},
					{name: '运行状态',field: 'isFanwen'},
				],
				"工商显示已注销": [
					{name: '企业名称',field: 'companyName'},
					{name: '注销时间',field: 'cancelDate'},
				],
				"未按期公示": [
					{name: '异常时间',field: 'putDate'},
					{name: '决定机关',field: 'putDepartment'},
					{name: '异常原因',field: 'putReason'},
				],
				"无法联系场所": [
					{name: '异常时间',field: 'putDate'},
					{name: '决定机关',field: 'putDepartment'},
					{name: '异常原因',field: 'putReason'},
				],
				"弄虚作假": [
					{name: '异常时间',field: 'putDate'},
					{name: '决定机关',field: 'putDepartment'},
					{name: '异常原因',field: 'putReason'},
				],
				"与问题平台股权关联": [
					{name: '关联企业名称',field: 'lineInfo'},
					{name: '关联关系类型',field: 'lineType'},
					{name: '问题描述',field: 'requestDesc'},
				],
				"与问题平台法人高管关联": [
					{name: '关联企业名称',field: 'companyName'},
					{name: '关联关系类型',field: 'lineType'},
					{name: '问题描述',field: 'requestDesc'},
				],
				"与问题平台地址电话关联": [
					{name: '关联企业名称',field: 'companyName'},
					{name: '关联关系类型',field: 'lineType'},
					{name: '问题描述',field: 'requestDesc'},
				],
				"实缴资本远低于注册资本": [
					{name: '注册资本',field: 'regCapital'},
					{name: '实缴资本',field: 'actualCapital'},
				],
				"刑事立案": [
					{name: '企业名称',field: 'companyName'},
					{name: '立案时间',field: 'earlyWarningFilingTime'},
				],
			},
		}
	},
	computed: {
		//无缝滚动
		classOption() {
			return {
				direction: 2,
				singleWidth: this.chronicleEvents.length * 229,
				limitMoveNum: 4,
				waitTime: 0,
				step: 1,
				hoverStop: true
			}
		}
	},
	created() {},
	mounted() {
		this.obtainRiskStatistics(); //风险指数
		this.getCompanyRiskPoint(); //风险标签
		this.getRiskTrendChart(); //风险变化趋势
		this.getChronicleEvents(); //大事记 
	},
	methods: {
		//风险指数
		obtainRiskStatistics() {
			this.$provider.get('/wg-ifrRisk/synthesize/getRiskIndex', {
				params: {
					companyId: this.$route.query.id
				}
			}).then(res => {
				if (res.data != "" && res.data != null) {
					this.riskNum = res.data.risk;
					let fumian = res.data.yuqingRisk;
					let yewu = res.data.yewuRisk;
					let sifa = res.data.sifaRisk;
					let yunying = res.data.yunyingRisk;
					let guanlian = res.data.guanlianRisk;
					let da = [
						{name: '业务风险',max: 100,num: yewu},
						{name: '舆情风险',max: 100,num: fumian},
						{name: '司法风险',max: 100,num: sifa},
						{name: '经营风险',max: 100,num: yunying},
						{name: '关联风险',max: 100,num: guanlian},
					]
					let daArr = [];
					daArr.push(yewu);
					daArr.push(fumian);
					daArr.push(sifa);
					daArr.push(yunying);
					daArr.push(guanlian);
					echartsRadar("chartsRadarBox", daArr, da, '#409EFF');
				}
			})
		},
		//风险标签
		getCompanyRiskPoint() {
			this.$provider.get('/wg-ifrRisk/synthesize/getRiskLabelList', {
				params: {
					companyId: this.$route.query.id
				}
			}).then(res => {
				this.LabelSifa = res.data.judicial;
				this.LabelYewu = res.data.business;
				this.LabelYuqing = res.data.sentiment;
				this.LabelYunying = res.data.operate;
				this.LabelZhoubian = res.data.relation;
			})
		},
		//风险标签  弹框详情   滚动列表    舆情、业务
		getLabelInfo(item, index) {
			this.labelVisibleY = true;
			this.titleLabelY = item.tagName;
			this.labelParamY = item;
			this.tableDataY = [];
			this.totalItemsY = 0;
			this.currentPageY = 1;
			this.getPageListY();
		},
		//风险标签  弹框详情   滚动列表    舆情、业务
		getPageListY() {
			this.$provider.get('/wg-ifrRisk/synthesize/getRiskPointDetailInfoList', {
				params: {
					companyId: this.$route.query.id,
					dimensionType: this.labelParamY.dimensionType,
					id: this.labelParamY.id,
					tagId: this.labelParamY.tagId,
					tableFlag: this.labelParamY.tableFlag,
					current: this.currentPageY,
					size: this.pageSizeY
				}
			}).then(res => {
				this.tableDataY = res.data.records;
				this.totalItemsY = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndexY(index) {
			return (this.currentPageY - 1) * this.pageSizeY + index + 1;
		},
		handleCurrentChangeY(val) {
			this.currentPageY = val;
			this.getPageListY(); //加载列表
		},
		//风险标签  弹框详情   列表    司法、经营、关联
		getLabelInfoF(item, index) {
			this.titleLabelF = item.tagName; //标签名称
			this.labelParamF = item;
			this.getFTable(); //封装 列表
		},
		//风险标签  弹框详情   列表封装   司法、经营、关联
		getFTable() {
			this.$provider.get('/wg-ifrRisk/synthesize/getRiskPointDetailInfoList', {
				params: {
					companyId: this.$route.query.id,
					dimensionType: this.labelParamF.dimensionType,
					id: this.labelParamF.id,
					tagId: this.labelParamF.tagId,
					tableFlag: this.labelParamF.tableFlag,
					current: this.currentPageF,
					size: this.pageSizeF,
				}
			}).then(res => {
				this.tableColumnsF = this.tableColumns[this.titleLabelF];
				this.labelVisibleF = true;
				if (this.titleLabelF == "网站打不开" || this.titleLabelF == "网站未备案" || this.titleLabelF == "网站在境外") {
					res.data.records.filter((item, index) => {
						if (item.isRecord == 0) {
							item.isRecord = "未备案";
						} else if (item.isRecord == 1) {
							item.isRecord = "已备案";
						}
						if (item.isOverseas == 0) {
							item.isOverseas = "境内";
						} else if (item.isOverseas == 1) {
							item.isOverseas = "境外";
						} else if (item.isOverseas == 2) {
							item.isOverseas = "未知";
						}
						if (item.isFanwen == 1) {
							item.isFanwen = "在运营";
						} else if (item.isFanwen == 2) {
							item.isFanwen = "否";
						} else if (item.isFanwen == 3) {
							item.isFanwen = "未知";
						}
					})
					this.tableDataF = res.data.records;
					this.totalItemsF = res.data.total;
				} else if (this.titleLabelF == "实缴资本远低于注册资本") {
					this.tableDataF = [];
					this.tableDataF.push(res.data);
					this.totalItemsF = 1;
				} else if (this.titleLabelF == "工商显示已注销") {
					this.tableDataF = [];
					this.tableDataF.push(res.data);
					this.totalItemsF = 1;
				} else if (this.titleLabelF == "刑事立案") {
					this.tableDataF = [];
					this.tableDataF.push(res.data);
					this.totalItemsF = 1;
				} else {
					this.tableDataF = res.data.records;
					this.totalItemsF = res.data.total;
				}
			})
		},
		handleCurrentChangeF(val) {
			this.currentPageF = val;
			this.getFTable(); //加载列表
		},
		//风险变化趋势
		getRiskTrendChart() {
			this.$provider.get('/wg-ifrRisk/synthesize/getRiskTrend', {
				params: {
					companyId: this.$route.query.id
				}
			}).then(res => {
				let da = res.data;
				if (da.length != 0) {
					let xArr = [],
						yArr = [],
						zArr = [];
					for (var i = 0; i < da.length; i++) {
						xArr.push(da[i].date);
						yArr.push(da[i].risk);
						zArr.push(da[i].label);
					}
					echartsLineAreaLabel("chartsLineBox", xArr, yArr, zArr, "#409EFF");
					this.showHide = false;
				} else {
					this.showHide = true;
				}
			})
		},
		//大事记
		getChronicleEvents() {
			this.$provider.get('/wg-ifrRisk/synthesize/getBigEvent', {
				params: {
					companyId: this.$route.query.id
				}
			}).then(res => {
				if (res.data.length > 3) {
					let aa = res.data;
					let bb = aa.concat(res.data);
					let cc = bb.concat(res.data);
					let dd = cc.concat(res.data);
					let ee = dd.concat(res.data);
					let ff = ee.concat(res.data);
					let gg = ff.concat(res.data);
					this.chronicleEvents = gg;
				} else {
					this.chronicleEvents = res.data;
				}
				document.getElementById("chronicleEventsContent").firstElementChild.style.width = this.chronicleEvents.length * 229 + "px";
			})
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
