<template>
	<div class="info-business">
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">非法经营 <span>{{totalItemsA}}</span> 条</h4>
					<div v-loading="loadingFlagA">
						<div class="enterprise-box" v-infinite-scroll="getPageListA">
							<myEnterprise :data="tableDataA" :dataEmpty="showHideA" difference="YW"></myEnterprise>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">涉嫌传销 <span>{{totalItemsB}}</span> 条</h4>
					<div v-loading="loadingFlagB">
						<div class="enterprise-box" v-infinite-scroll="getPageListB">
							<myEnterprise :data="tableDataB" :dataEmpty="showHideB" difference="YW"></myEnterprise>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">高息诱导 <span>{{totalItemsC}}</span> 条</h4>
					<div v-loading="loadingFlagC">
						<div class="enterprise-box" v-infinite-scroll="getPageListC">
							<myEnterprise :data="tableDataC" :dataEmpty="showHideC" difference="YW"></myEnterprise>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="content-box">
					<h4 class="title-h4">违规宣传 <span>{{totalItemsD}}</span> 条</h4>
					<div v-loading="loadingFlagD">
						<div class="enterprise-box" v-infinite-scroll="getPageListD">
							<myEnterprise :data="tableDataD" :dataEmpty="showHideD" difference="YW"></myEnterprise>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import myEnterprise from '../../../components/my-enterprise.vue'
export default {
	name: 'info-business',
	components: { myEnterprise },
	data() {
		return {
			loadingFlagA: false,
			loadingFlagB: false,
			loadingFlagC: false,
			loadingFlagD: false,
			showHideA: false,
			showHideB: false,
			showHideC: false,
			showHideD: false,
			tableDataA: [], //列表  数组
			currentPageA: 0, //当前页
			pageSizeA: 10, //每页  条数
			totalItemsA: 0, //列表 总数
			tableDataB: [], //列表  数组
			currentPageB: 0, //当前页
			pageSizeB: 10, //每页  条数
			totalItemsB: 0, //列表 总数
			tableDataC: [], //列表  数组
			currentPageC: 0, //当前页
			pageSizeC: 10, //每页  条数
			totalItemsC: 0, //列表 总数
			tableDataD: [], //列表  数组
			currentPageD: 0, //当前页
			pageSizeD: 10, //每页  条数
			totalItemsD: 0, //列表 总数
		}
	},
	created() {},
	methods: {
		//非法经营
		getPageListA() {
			this.loadingFlagA = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/business/getIllegalOperationList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPageA += 1,
					size: this.pageSizeA
				}
			}).then(res => {
				this.loadingFlagA = false; //loading 消失
				if (res.data.total != 0) {
					for (var i = 0; i < res.data.records.length; i++) {
						this.tableDataA.push(res.data.records[i]);
					}
					this.totalItemsA = res.data.total;
					this.showHideA = false;
				} else {
					this.showHideA = true;
				}
			})
		},
		//涉嫌传销
		getPageListB() {
			this.loadingFlagB = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/business/getPyramidSellingList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPageB += 1,
					size: this.pageSizeB
				}
			}).then(res => {
				this.loadingFlagB = false; //loading 消失
				if (res.data.total != 0) {
					for (var i = 0; i < res.data.records.length; i++) {
						this.tableDataB.push(res.data.records[i]);
					}
					this.totalItemsB = res.data.total;
					this.showHideB = false;
				} else {
					this.showHideB = true;
				}
			})
		},
		//高息诱导
		getPageListC() {
			this.loadingFlagC = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/business/getInducementList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPageC += 1,
					size: this.pageSizeC
				}
			}).then(res => {
				this.loadingFlagC = false; //loading 消失
				if (res.data.total != 0) {
					for (var i = 0; i < res.data.records.length; i++) {
						this.tableDataC.push(res.data.records[i]);
					}
					this.totalItemsC = res.data.total;
					this.showHideC = false;
				} else {
					this.showHideC = true;
				}
			})
		},
		//违规宣传
		getPageListD() {
			this.loadingFlagD = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/business/getIllegalPublicityList', {
				params: {
					companyId: this.$route.query.id,
					current: this.currentPageD += 1,
					size: this.pageSizeD
				}
			}).then(res => {
				this.loadingFlagD = false; //loading 消失
				if (res.data.total != 0) {
					for (var i = 0; i < res.data.records.length; i++) {
						this.tableDataD.push(res.data.records[i]);
					}
					this.totalItemsD = res.data.total;
					this.showHideD = false;
				} else {
					this.showHideD = true;
				}
			})
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
